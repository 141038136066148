import { parse } from 'logic/Excel';
import { clone, getSetMap, isParsable, perf, round } from 'shared/utils';
import { add, sub, div, gtz, mul } from 'shared/big';
import { cut } from 'shared/format';

import {
  GLOBAL_VAR_RGX,
  LOCAL_VAR_COL_ROW_RGX,
  LOCAL_VAR_RGX,
  OUTLAY_VAR_MAP,
  COL_PROP_MAP,
  WORK_PROPS,
  ROOM_FORMULAIC_PROPS,
  ROOM_PROPS,
  ROUND_PROPS,
} from '../const';

import { parseOutlay } from './parseOutlay';
import { findByIdOrUuid } from './findByIdOrUuid';

const COMPLETE_EVENT = new Event('complete');

class Calc extends EventTarget {
  constructor(deal, outlay, workTypes, materialTypes, outlayVariables, discountConstraints) {
    super();

    this.deal = clone(deal);
    this.outlay = parseOutlay(outlay, workTypes, materialTypes);
    this.id = this.outlay.id || this.outlay.uuid;
    this.isVAT = this.outlay.VAT;
    this.isFinal = this.outlay.isFinal ?? false;
    this.technologyDisclaimer = this.outlay.technologyDisclaimer ?? false;

    this.outlayVariables = outlayVariables;
    this.bonusCoefficient = null;
    this.outlayVariables = outlayVariables.map((variable) => {
      const result = { ...variable, nameId: OUTLAY_VAR_MAP[variable.name] };
      if (result.nameId === 'bonusCoefficient') this.bonusCoefficient = result;
      return result;
    });

    this.workTypes = workTypes;
    this.materialTypes = materialTypes;
    this.specialWorkTypeId = workTypes.find(({ rid }) => rid === 8)?.id;
    this.doorMatTypeId = materialTypes.find(
      ({ workTypeId, rid }) => workTypeId === this.specialWorkTypeId && rid === 2
    )?.id;

    this.anyDiscountAllowed = this.deal.anyDiscountAllowed;
    this.maxDiscountOverride = this.deal.maxDiscountOverride;
    this.discountConstraints = discountConstraints;

    this.rooms = this.outlay.rooms;
    this.rectifiedRooms = [];
    this.showDiscount = false;

    this.dependenciesPos = {};
    this.dependenciesRoom = {};
    this.dependenciesOutlay = {};

    this.genDepTree();
    // this.getterCache = {};
    // this.roundGetterCache = {};
    this.result = {};
  }

  calcRoomProps(room) {
    const updatedProps = [];

    for (const prop of ROOM_FORMULAIC_PROPS) {
      const roomVariable = this.outlayVariables.find((ov) => ov.nameId == prop);
      if (!roomVariable) continue;

      if (!roomVariable.formula) {
        room[roomVariable.nameId] = roomVariable.defaultValue;
        continue;
      }

      const propManual = `${prop}Manual`;
      if (isParsable(room[propManual])) {
        room[roomVariable.nameId] = cut(room[propManual], 2);
      } else {
        const getter = parse(roomVariable.formula, { deal: this.deal, outlay: this.outlay, room });
        const result = cut(getter(), 1);
        room[roomVariable.nameId] = gtz(result) ? result : 0;
      }

      updatedProps.push(roomVariable.nameId);
    }

    return updatedProps;
  }

  calculateAll() {
    for (const room of this.rooms) {
      this.calcRoomProps(room);

      for (const work of room.roomPositions)
        for (const prop of WORK_PROPS) this.calcWork(work, prop);
    }

    for (const room of this.rooms) this.calculateRoomSum(room);

    this.finishCalculations();

    this.clearCalcedFlags();
  }

  calcByPositions() {
    return this.rectifiedRooms.reduce((acc, room) => [...acc, ...room.workList], []);
  }

  calcByWorkType(sumKey = 'sum', priceKey = 'price', includeBonus = false) {
    const result = new Map();
    for (const { workTree } of this.rectifiedRooms) {
      for (const { name, [sumKey]: sum, fullSum, matTypes, bonus, ...other } of workTree) {
        const wtRef = getSetMap(result, name, {
          ...other,
          name,
          sum: 0,
          fullSum: 0,
          bonus: 0,
          works: new Map(),
        });
        wtRef.sum = add(wtRef.sum, sum);
        wtRef.fullSum = add(wtRef.fullSum, fullSum);
        if (includeBonus) wtRef.bonus = add(wtRef.bonus, bonus);

        for (const { works } of matTypes) {
          for (const work of works) {
            const workRef = getSetMap(wtRef.works, work.name, {
              ...work,
              price: work[priceKey],
              sum: 0,
              fullSum: 0,
              quantity: 0,
              bonus: 0,
            });
            workRef.sum = add(workRef.sum, work[sumKey]);
            workRef.fullSum = add(workRef.sum, work.fullSum);
            workRef.quantity = add(workRef.quantity, work.quantity);
            workRef.bonus = add(workRef.bonus, work.bonus);
          }
        }
      }
    }

    return [...result.values()].map((workType) => ({
      ...workType,
      works: [...workType.works.values()],
    }));
  }

  calcOneWorkType(workTypeId) {
    const workType = this.workTypes.find(findByIdOrUuid(workTypeId));
    if (!workType) return null;
    return this.calcByWorkType().find(({ name }) => name === workType.name) || null;
  }

  calcByWorkAndMatType() {
    return this.rectifiedRooms;
  }

  calcSummaryTable(shouldForceMinimalTypeSums = false, overrideDiscountValue = 0) {
    let showDiscount = false;
    let showVat = !!this.outlay.VAT;

    const totalRow = {
      name: 'ИТОГО ПО ВСЕМ РАЗДЕЛАМ',
      sum: 0,
      fullSum: 0,
      discountSum: 0,
      vatSum: 0,
    };

    const dataMap = new Map();
    for (const room of this.rectifiedRooms) {
      for (const { name, fullSum, discountSum, sum, id } of room.workTree) {
        const workType = getSetMap(dataMap, name, {
          id,
          name,
          sum: 0,
          fullSum: 0,
          discountSum: 0,
          vatSum: 0,
        });

        workType.sum = add(workType.sum, sum);
        workType.fullSum = add(workType.fullSum, fullSum);
        workType.discountSum = add(workType.discountSum, discountSum);
        workType.vatSum = div(sum, 6).add(workType.vatSum);
      }
    }

    const data = [];
    for (const workType of dataMap.values()) {
      if (shouldForceMinimalTypeSums) {
        const foundWorkType = this.workTypes.find((type) => type.name === workType.name);
        const minimalSum = gtz(foundWorkType?.minimalSum) ? round(foundWorkType.minimalSum) : null;
        if (minimalSum && minimalSum > workType.fullSum) {
          workType.sum = minimalSum;
          workType.fullSum = minimalSum;
          workType.discountSum = 0;
          workType.vatSum = div(minimalSum, 6);
        }
      }

      totalRow.sum = add(totalRow.sum, workType.sum);
      totalRow.fullSum = add(totalRow.fullSum, workType.fullSum);
      totalRow.discountSum = add(totalRow.discountSum, workType.discountSum);
      totalRow.vatSum = add(totalRow.vatSum, workType.vatSum);

      data.push(workType);
    }

    let total = totalRow.sum;
    let discount = 0;
    let prepayment = 0;
    let prepaymentNP = 0;
    let promotionSumNP = 0;
    let promotionSumCleaning = 0;

    if (this.outlay.type !== 'act' && showVat === false) showDiscount = gtz(totalRow.discountSum);

    const isDiscountOverride = gtz(overrideDiscountValue);
    if (this.outlay.type === 'act' && !this.isAllActs) {
      const act = this.subActs[this.actNumber];
      if (act) {
        total = totalRow.fullSum;

        if (gtz(act.discountValue)) {
          showDiscount = true;
          discount = act.discountValue;
          total = sub(total, act.discountValue);
        }
        if (gtz(act.prepayment)) {
          prepayment = act.prepayment;
          total = sub(total, prepayment);
        }
        if (gtz(act.prepaymentNP)) {
          prepaymentNP = act.prepaymentNP;
          total = sub(total, prepaymentNP);
        }
        if (gtz(act.promotionSumNP)) {
          promotionSumNP = act.promotionSumNP;
          total = sub(total, promotionSumNP);
        }
        if (gtz(act.promotionSumCleaning)) {
          promotionSumCleaning = act.promotionSumCleaning;
          total = sub(total, promotionSumCleaning);
        }
      }
    } else {
      prepayment = this.deal.prepayment;
      prepaymentNP = this.deal.prepaymentNP;

      if (this.isFinalOutlay) {
        promotionSumNP = this.promotionSumNP;
        if (promotionSumNP && !isDiscountOverride) total = sub(total, promotionSumNP);
        promotionSumCleaning = this.promotionSumCleaning;
        if (promotionSumCleaning && !isDiscountOverride) total = sub(total, promotionSumCleaning);
      }
    }

    if (isDiscountOverride) {
      discount = overrideDiscountValue;
      showDiscount = true;
      total = sub(total, overrideDiscountValue);
      if (gtz(prepayment)) total = sub(total, prepayment);
      if (gtz(prepaymentNP)) total = sub(total, prepaymentNP);

      if (this.isFinalOutlay) {
        if (gtz(promotionSumNP)) total = sub(total, promotionSumNP);
        if (gtz(promotionSumCleaning)) total = sub(total, promotionSumCleaning);
      }
    }

    const type = this.outlay.type;
    const isFinalOutlay = this.isFinalOutlay;

    return {
      isFinalOutlay,
      type,
      showDiscount,
      showVat,
      data,
      totalRow,
      discount,
      total,
      prepayment,
      prepaymentNP,
      promotionSumNP,
      promotionSumCleaning,
    };
  }

  calcSummaryWageTable(wageKey, includeBonus = false) {
    const totalRow = { name: 'ИТОГО ПО ВСЕМ РАЗДЕЛАМ', sum: 0, bonus: 0 };

    const dataMap = new Map();
    for (const room of this.rectifiedRooms) {
      for (const { name, [wageKey]: sum, bonus } of room.workTree) {
        const workType = getSetMap(dataMap, name, { name, sum: 0, bonus: 0 });

        workType.sum = add(workType.sum, sum || 0);
        if (includeBonus) workType.bonus = add(workType.bonus, bonus || 0);
      }
    }

    const data = [];
    for (const workType of dataMap.values()) {
      totalRow.sum = add(totalRow.sum, workType.sum);
      if (includeBonus) totalRow.bonus = add(totalRow.bonus, workType.bonus);

      data.push(workType);
    }

    return { data, totalRow, total: totalRow.sum };
  }

  updatePosValue(roomPositionId, roomId, prop, newVal) {
    if (prop === 'customName') {
      const room = this.rooms.find(findByIdOrUuid(roomId));
      const roomPosition = room.roomPositions.find(findByIdOrUuid(roomPositionId));
      roomPosition[prop] = newVal;
      return;
    }

    this._updatePosValue(roomPositionId, roomId, prop, newVal);
    this.finishCalculations();

    this.clearCalcedFlags();
  }

  updatePosValueBatch(changes) {
    for (const { roomId, roomPositionId, prop, value } of changes) {
      this._updatePosValue(roomPositionId, roomId, prop, value);
    }
    this.finishCalculations();

    this.clearCalcedFlags();
  }

  updateRoomValue(roomId, prop, newVal) {
    const room = this.rooms.find(findByIdOrUuid(roomId));

    this._updateRoomValue(room, prop, newVal);

    this.finishCalculations();

    this.clearCalcedFlags();
  }

  _updateRoomValue(room, prop, newVal) {
    room[prop] = newVal;
    if (prop === 'name' || prop === 'roomType') return;

    const prevCoefficient = room.coefficient;
    const updatedRoomProps = this.calcRoomProps(room);
    prop = prevCoefficient !== room.coefficient ? 'coefficient' : prop;

    // const dependentRoomPositions = this.updateRoomChildren(room, prop);
    this.updateRoomChildren(room, prop);
    for (const roomProp of updatedRoomProps) {
      this.updateRoomChildren(room, roomProp);
      // const updatedDependentRoomPositions = this.updateRoomChildren(room, roomProp);
      // dependentRoomPositions.push(updatedDependentRoomPositions);
    }

    this.calculateRoomSum(room);
  }

  addPromotion(promotion) {
    this.outlay.outlayPromotions = [...this.outlay.outlayPromotions, promotion];
    this.finishCalculations();
  }

  removePromotion(promotionId) {
    this.outlay.outlayPromotions = this.outlay.outlayPromotions.filter(
      ({ id }) => id !== promotionId
    );
    this.finishCalculations();
  }

  updatePromotionId(oldId, newId) {
    const promotion = this.outlay.outlayPromotions?.find(findByIdOrUuid(oldId));
    if (promotion) promotion.id = newId;
  }

  updatePromotionValue(promotionId, data) {
    this.outlay.outlayPromotions = this.outlay.outlayPromotions.map((promo) => {
      if (promo.id !== promotionId) return promo;
      return { ...promo, ...data };
    });
    this.finishCalculations();
  }

  updateDealValue(prop, newVal) {
    this.deal[prop] = round(newVal);

    this.updateOutlayChildren(prop);

    for (const room of this.rooms) this.calculateRoomSum(room);

    this.finishCalculations();
  }

  updatePositionId(roomId, oldId, newId) {
    const room = this.rooms.find(findByIdOrUuid(roomId));
    const roomPosition = room.roomPositions.find(findByIdOrUuid(oldId));
    if (roomPosition) roomPosition.id = newId;
  }

  updateRoomId(oldId, newId) {
    const room = this.rooms.find(findByIdOrUuid(oldId));
    if (!room) return;

    room.id = newId;

    if (room.roomPositions)
      for (const roomPosition of room.roomPositions) roomPosition.roomId = newId;

    this.dependenciesRoom = Calc.changeKey(this.dependenciesRoom, oldId, newId);
    this.dependenciesPos = Calc.changeKey(this.dependenciesPos, oldId, newId);
  }

  updateActNumber() {
    return undefined;
  }

  updateSubActId() {
    return undefined;
  }

  updateSubAct() {
    return undefined;
  }

  static changeKey(depTree, oldKey, newKey) {
    return Object.entries(depTree).reduce((newTree, [key, value]) => {
      const result = key?.toString() === oldKey?.toString() ? newKey : key;
      return { ...newTree, [result]: value };
    }, {});
  }

  updatePositionChildren(roomPosition, roomId, prop) {
    const dependentRooms = this.dependenciesPos[roomId];
    if (!dependentRooms) return;

    const dependentRow = dependentRooms[roomPosition.position.excelRow];
    if (!dependentRow) return;

    const childRoomPositions = dependentRow[prop];
    if (!childRoomPositions) return;

    for (const { roomPosition, prop } of childRoomPositions) {
      this.calcWork(roomPosition, prop, true);
      this.updatePositionChildren(roomPosition, roomId, prop.replace('Formula', ''));
    }
  }

  updateRoomChildren(room, prop) {
    const roomPositions = [];
    const dependentRoomProps = this.dependenciesRoom[room.id] || this.dependenciesRoom[room.uuid];
    if (!dependentRoomProps) return roomPositions;

    const childRoomPositions = dependentRoomProps[prop];
    if (!childRoomPositions) return roomPositions;

    for (const { roomPosition, prop } of childRoomPositions) {
      this.calcWork(roomPosition, prop, true);
      this.updatePositionChildren(roomPosition, room.id, prop.replace('Formula', ''));
      roomPositions.push(roomPosition);
    }

    return roomPositions;
  }

  updateOutlayChildren(prop) {
    const childRoomPositions = this.dependenciesOutlay[prop];
    if (!childRoomPositions) return;

    for (const { roomPosition, prop } of childRoomPositions) {
      this.calcWork(roomPosition, prop, true);
      this.updatePositionChildren(roomPosition, roomPosition.roomId, prop.replace('Formula', ''));
    }
  }

  calculateRoomSum(room) {
    room.sum = this.positionSumReducer(room.roomPositions);
  }

  genDepTree() {
    for (const room of this.rooms) {
      for (const work of room.roomPositions) {
        work.children = {};
        for (const prop of WORK_PROPS) work.children[prop] = this.getChildren(work, prop);
      }

      for (const work of room.roomPositions) {
        for (const [propFormula, children] of Object.entries(work.children)) {
          for (const { room, row, prop } of children) {
            if (room !== undefined) {
              if (!this.dependenciesRoom[room]) this.dependenciesRoom[room] = {};
              if (!this.dependenciesRoom[room][prop]) this.dependenciesRoom[room][prop] = [];
              this.dependenciesRoom[room][prop].push({
                roomPosition: work,
                prop: propFormula,
              });
            } else if (row !== undefined) {
              if (!this.dependenciesPos[work.roomId]) this.dependenciesPos[work.roomId] = {};
              if (!this.dependenciesPos[work.roomId][row])
                this.dependenciesPos[work.roomId][row] = {};
              if (!this.dependenciesPos[work.roomId][row][prop])
                this.dependenciesPos[work.roomId][row][prop] = [];
              this.dependenciesPos[work.roomId][row][prop].push({
                roomPosition: work,
                prop: propFormula,
              });
            } else {
              if (!this.dependenciesOutlay[prop]) this.dependenciesOutlay[prop] = [];
              this.dependenciesOutlay[prop].push({
                roomPosition: work,
                prop: propFormula,
              });
            }
          }
        }
      }
    }
  }

  getChildren(roomPosition, prop) {
    const formula = roomPosition.position[prop + 'Formula'];
    if (!formula) return [];

    const varNames = formula.match(GLOBAL_VAR_RGX) || [];
    const cellNames = formula.match(LOCAL_VAR_RGX) || [];

    const children = [];

    for (const cellName of cellNames) {
      if (!cellName) continue;
      const [strCol, strRow] = cellName.match(LOCAL_VAR_COL_ROW_RGX) || [];

      children.push({ row: +strRow, prop: COL_PROP_MAP[strCol] });
    }

    for (const varName of varNames) {
      if (ROOM_PROPS.includes(varName)) {
        children.push({ room: roomPosition.roomId, prop: OUTLAY_VAR_MAP[varName] });
      } else if (['С_СМЕТ', 'К_СМЕТ', 'УСЛОВИЯ_РАБОТЫ', 'ДИЗАЙН_ПРОЕКТ'].includes(varName)) {
        children.push({ prop: OUTLAY_VAR_MAP[varName] });
      }
    }

    return children;
  }

  clearCalcedFlags() {
    for (const room of this.rooms)
      for (const roomPosition of room.roomPositions)
        for (const prop of WORK_PROPS) roomPosition[prop + 'Ready'] = false;
  }

  compileFormula(work, prop) {
    const getterProp = `${prop}Getter`;
    const shouldRound = !ROUND_PROPS.includes(prop);
    const formula = work.position[`${prop}Formula`];

    const getter = parse(formula, {
      deal: this.deal,
      outlay: this.outlay,
      room: work.room,
      work,
    });

    if (!shouldRound) {
      work[getterProp] = getter;
      return;
    }

    work[getterProp] = () => {
      const result = getter();
      return result instanceof Big ? result.toScale(2) : cut(result, 2);
    };
  }

  /* Cached version */
  // _compileFormula(work, prop) {
  //   const getterProp = `${prop}Getter`;
  //   const shouldRound = !ROUND_PROPS.includes(prop);
  //   const formula = work.position[`${prop}Formula`];

  //   const cachedGetter = this.getterCache[formula];
  //   if (cachedGetter) {
  //     if (!shouldRound) {
  //       work[getterProp] = cachedGetter;
  //       return;
  //     }

  //     const cachedRoundGetter = this.roundGetterCache[formula];
  //     if (cachedRoundGetter) {
  //       work[getterProp] = cachedRoundGetter;
  //       return;
  //     }

  //     work[getterProp] = this.roundGetterCache[formula] = () => {
  //       const result = cachedGetter();
  //       return result instanceof Big ? result.toScale(2) : cut(result, 2);
  //     };
  //     return;
  //   }

  //   const getter = this.getterCache[formula] = parse(formula, {
  //     deal: this.deal,
  //     outlay: this.outlay,
  //     room: work.room,
  //     work,
  //   });

  //   if(!shouldRound) {
  //     work[getterProp] = getter;
  //     return;
  //   }

  //     work[getterProp] = this.roundGetterCache[formula] = () => {
  //     const result = getter();
  //     return result instanceof Big ? result.toScale(2) : cut(result, 2);
  //   };
  // }

  calcWork(work, prop, force = false) {
    if (!work) return '0';
    if (prop === 'basePrice') return work.priceManual ?? work.position.basePrice;

    if (prop === 'bonus') {
      const result = work.hasDiscount
        ? mul(work.masterWageCalculated, this.bonusCoefficient.defaultValue).toScale(2).toString()
        : '0';
      work.bonusCalculated = result;
      work.bonusReady = true;

      return result;
    }

    const manualProp = `${prop}Manual`;
    if (prop !== 'price' && isParsable(work[manualProp])) return work[manualProp];

    const calcedProp = `${prop}Calculated`;
    const readyProp = `${prop}Ready`;
    if (work[calcedProp] && work[readyProp] && !force) return work[calcedProp];

    const formulaProp = `${prop}Formula`;
    if (!work.position[formulaProp]) return work.position[prop] || '0';

    this.compileFormula(work, prop);
    const result = work[`${prop}Getter`]();
    work[calcedProp] = result;
    work[readyProp] = true;

    return result?.toString() || '0';
  }

  finishCalculations() {
    this._finishCalculations();
    this.dispatchEvent(COMPLETE_EVENT);
  }
}

export default Calc;
