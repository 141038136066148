import { deleteDB, openDB, OpenDBCallbacks } from 'idb';

import { logger, requestExpect } from 'shared/utils';

import { updateStoresFactory } from '../helpers';

import { MAX_CACHE_SIZE, VERSION_IDB_STORE_NAMES, VER_STORE_PREFIX } from './consts';
import { VersionIdbSchema } from './types';

const getStoredVersions = async () => {
  const entries = await indexedDB.databases();

  return entries
    .filter((entry) => entry.name!.includes(VER_STORE_PREFIX))
    .map((entry) => +entry.name!.replace(VER_STORE_PREFIX, ''))
    .sort((a, b) => b - a);
};

const openVersionIdb = (contextVersion: number, initCallbacks: OpenDBCallbacks<VersionIdbSchema>) =>
  openDB(`${VER_STORE_PREFIX}${contextVersion}`, 1, initCallbacks);

export const initVersionIdb = async (
  idbVersion: number | 'latest' = 'latest',
  initCallbacks: OpenDBCallbacks<VersionIdbSchema> = {}
) => {
  const existingVersions = await getStoredVersions();
  if (idbVersion !== 'latest' && existingVersions.includes(idbVersion))
    return openVersionIdb(idbVersion, initCallbacks);

  if (idbVersion === 'latest') {
    idbVersion = ((await requestExpect.get('/context/version')) as any)?.version;
    if (!idbVersion) return logger.error('init version idb', 'coud not query latest version');
  }

  const url = `/context/version/${idbVersion}`;
  const responses = await Promise.all(
    VERSION_IDB_STORE_NAMES.map((name) => requestExpect.get(url, { name }))
  );

  const data: Record<string, any> = { id: idbVersion };
  for (const response of responses) {
    if (!response) return logger.error('init version idb', 'coud not load table');

    Object.assign(data, response);
  }

  if (!initCallbacks.upgrade)
    Object.assign(initCallbacks, {
      upgrade: updateStoresFactory(VERSION_IDB_STORE_NAMES, data),
    });

  const idb = await openVersionIdb(idbVersion as number, initCallbacks);
  if (existingVersions.includes(idbVersion)) return idb;

  if (existingVersions.length > MAX_CACHE_SIZE) {
    const excessVersions = existingVersions.slice(MAX_CACHE_SIZE);
    for (const version of excessVersions) {
      if (version === (idbVersion as any as number)) continue;
      deleteDB(`${VER_STORE_PREFIX}${version}`);
    }
  }

  return idb;
};
