import { IDBPDatabase, StoreNames, StoreValue } from 'idb';
import { logger } from 'shared/utils';

import { LOAD_EVENT, NO_IDB_PROMISE, UNLOAD_EVENT } from '../consts';

import { initVersionIdb } from './init';
import { VersionIdbSchema } from './types';

export class VersionIdbController extends EventTarget {
  idb: IDBPDatabase<VersionIdbSchema> | null = null;
  promiseCache: {
    [key in StoreNames<VersionIdbSchema>]?: Promise<StoreValue<VersionIdbSchema, key>[]>;
  } = {};

  async load(version?: 'latest' | number) {
    if (this.idb !== null && version === this.idb.version) return;

    this.unload();

    try {
      this.idb = (await initVersionIdb(version))!;
      this.dispatchEvent(LOAD_EVENT);
    } catch (error) {
      logger.error('static idb', error);
      this.idb = null;
    }
  }

  unload() {
    if (this.idb === null) return;
    this.dispatchEvent(UNLOAD_EVENT);
    this.idb.close();
    this.idb = null;
    this.promiseCache = {};
  }

  getAll<N extends StoreNames<VersionIdbSchema>>(
    storeName: N
  ): Promise<VersionIdbSchema[N]['value'][]> {
    if (!this.idb) return NO_IDB_PROMISE;

    if (this.promiseCache.hasOwnProperty(storeName)) return this.promiseCache[storeName]!;

    const transaction = this.idb.transaction(storeName);
    const promise = transaction.store.getAll();
    this.promiseCache[storeName] = promise as any;
    return promise;
  }
}
