import { openDB, OpenDBCallbacks } from 'idb';

import { logger, requestExpect } from 'shared/utils';

import { updateStoresFactory } from '../helpers';

import { STATIC_IDB_STORE_NAMES } from './consts';
import { StaticIdbSchema } from './types';

export const initStaticIdb = async (initCallbacks: OpenDBCallbacks<StaticIdbSchema> = {}) => {
  const version = ((await requestExpect.get('/context/static', { name: 'version' })) as any)
    ?.version;
  if (!version) return logger.error('init static idb', 'coud not load version');

  const responses = await Promise.all(
    STATIC_IDB_STORE_NAMES.map((name) => requestExpect.get('/context/static', { name }))
  );

  const data: Record<string, any> = {};
  for (const response of responses) {
    if (!response) return logger.error('init static idb', 'coud not load table');

    Object.assign(data, response);
  }

  if (!initCallbacks.upgrade)
    Object.assign(initCallbacks, {
      upgrade: updateStoresFactory(STATIC_IDB_STORE_NAMES, data),
    });

  return openDB<StaticIdbSchema>('context-static', version, initCallbacks);
};
