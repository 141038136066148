import { cut } from 'shared/format';
import { sumObjects } from 'shared/utils';

export const roomFilter = (room) => Math.sign(room.sum) === 1;

export const workSorter = (a, b) => {
  const aWorkTypeId = a.customWorkTypeId ?? a.position?.workTypeId ?? Infinity;
  const bWorkTypeId = b.customWorkTypeId ?? b.position?.workTypeId ?? Infinity;
  if (aWorkTypeId !== bWorkTypeId) return aWorkTypeId > bWorkTypeId ? 1 : -1;

  const aWorkId = a.position?.id ?? Infinity;
  const bWorkId = b.position?.id ?? Infinity;
  return aWorkId === bWorkId ? 0 : aWorkId > bWorkId ? 1 : -1;
};

export const workChunker = (work, { array, index }) => {
  const prevWork = array[index - 1];
  if (!prevWork) return false;
  if (work.custom || prevWork.custom) return work.name !== prevWork.name;
  return work.workId !== prevWork.workId;
};

export const getWorkFilter = (actNumber) => {
  if (actNumber === undefined) return (work) => !work.isTurnedOff;

  const normActNumber = +actNumber;
  if (normActNumber === -2) return (work) => {
    if (work.isTurnedOff) return false;
    return +work.actNumber !== 0;
  }

  return (work) => {
    if (work.isTurnedOff) return false;
    return +work.actNumber === normActNumber;
  };
};

const prepareWork = (work, workType, matType, workTypeId, materialTypeId) => ({
  id: work.id,
  workId: work.position?.id || null,
  key: 'RECT_WORK-' + work.id,
  custom: work.custom || false,
  name: work.customName || work.position?.name || '-',
  unit: work.customUnit || work.position?.unit || '-',
  manual: work.quantityManual !== null,
  quantity: cut(work.quantityManual ?? work.quantityCalculated),
  basePrice: cut(work.priceManual ?? work.position?.basePrice),
  price: work.priceCalculated || 0,
  sum: work.sumCalculated || 0,
  fullPrice: work.fullPriceCalculated || 0,
  fullSum: work.fullSumCalculated || 0,
  discountSum: work.discountSumCalculated || 0,
  coefficient: work.coefficientCalculated || 0,
  masterPrice: work.masterPriceCalculated || 0,
  masterWage: work.masterWageCalculated || 0,
  shiftPrice: work.shiftPriceCalculated || 0,
  shiftWage: work.shiftWageCalculated || 0,
  bonus: work.bonusCalculated || 0,
  workType,
  matType,
  workTypeId,
  materialTypeId,
});

export const getWorkMapper = (workTypes, matTypes) => (work) => {
  const workTypeId = work.customWorkTypeId || work.position?.workTypeId;
  const workType = (workTypeId && workTypes.find(({ id }) => id === workTypeId)?.name) || 'Прочие';

  const matTypeId = work.customMaterialTypeId || work.position?.materialTypeId;
  const matType = (matTypeId && matTypes.find(({ id }) => id === matTypeId)?.name) || 'прочие';

  return prepareWork(work, workType, matType, workTypeId, matTypeId);
};

export const workSumMapper = (workGroup) =>
  sumObjects(workGroup, {
    action: 'exclude',
    keys: ['basePrice', 'price', 'fullPrice', 'manual', 'coefficient', 'masterPrice', 'shiftPrice', 'custom'],
  });

export const prepareRoom = (
  room,
  roomSum,
  roomFullSum,
  roomDiscountSum,
  roomMasterWage,
  roomShiftWage,
  roomBonus,
  workTree,
  workList
) => ({
  id: room.id,
  key: 'RECT_ROOM-' + room.id,
  isBathroom: room.isBathroom || false,
  name: room.name || room.roomType?.name || '-',
  sum: room.sum || roomSum || 0,
  fullSum: roomFullSum || 0,
  discountSum: roomDiscountSum || 0,
  masterWage: roomMasterWage || 0,
  shiftWage: roomShiftWage || 0,
  coefficient: room.coefficient || 1,
  length: room.length || 0,
  width: room.width || 0,
  height: room.height || 0,
  openings: room.openings || 0,
  perimeter: cut(room.perimeterManual || room.perimeter),
  areaFloor: cut(room.areaFloorManual || room.areaFloor),
  areaWalls: cut(room.areaWallsManual || room.areaWalls),
  outletNumber: (room.outletNumber | 0) || 0,
  slope: room.slope || 0,
  bonus: roomBonus || 0,
  workTree,
  workList,
});
